<template>
  <nav class="nav-bar">
    <div class="nav-container">
      <div class="logo">
        <router-link to="/">
          <img src="@/assets/images/logo.png" alt="旅行网">
        </router-link>
      </div>

      <ul class="nav-list">
        <li>
          <router-link to="/flight">机票</router-link>
        </li>
        <li>
          <router-link to="/train">火车票</router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar'
}
</script>

<style lang="scss" scoped>
.nav-bar {
  background: #2681ff;
  color: white;
}

.nav-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.logo {
  margin-right: 40px;
  
  img {
    height: 40px;
    vertical-align: middle;
  }
}

.nav-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  
  li {
    a {
      display: block;
      padding: 15px 25px;
      color: white;
      text-decoration: none;
      font-size: 16px;
      
      &:hover, &.router-link-active {
        background: rgba(255,255,255,0.1);
      }
    }
  }
}
</style> 