<template>
  <div class="register-page">
    <div class="register-container">
      <div class="register-box">
        <div class="register-header">
          <h2>注册</h2>
          <div class="switch-mode">
            已有账号？
            <router-link to="/login" class="switch-link">去登录</router-link>
          </div>
        </div>

        <el-form 
          ref="form" 
          :model="formData" 
          :rules="rules"
          label-position="top"
        >
         <el-form-item prop="phone" label="手机号">
            <el-input 
              v-model="formData.phone"
              prefix-icon="el-icon-mobile-phone"
              placeholder="请输入手机号"
            />
          </el-form-item>
          <el-form-item prop="password" label="密码">
            <el-input 
              v-model="formData.password"
              prefix-icon="el-icon-lock"
              type="password"
              placeholder="请输入密码"
              show-password
            />
          </el-form-item>

          <el-form-item prop="confirmPassword" label="确认密码">
            <el-input 
              v-model="formData.confirmPassword"
              prefix-icon="el-icon-lock"
              type="password"
              placeholder="请再次输入密码"
              show-password
            />
          </el-form-item>

          

          <el-form-item prop="verify" label="验证码">
            <div class="verification-code">
              <el-input 
                v-model="formData.verify"
                placeholder="请输入验证码"
              />
              <el-button 
                type="primary" 
                :disabled="cooldown > 0"
                @click="sendVerificationCode"
              >
                {{ cooldown > 0 ? `${cooldown}s后重新发送` : '获取验证码' }}
              </el-button>
            </div>
          </el-form-item>

          <el-form-item>
            <el-button 
              type="primary" 
              class="submit-btn" 
              :loading="loading"
              @click="handleSubmit"
            >
              注册
            </el-button>
          </el-form-item>
        </el-form>

        <div class="register-agreement">
          注册即代表同意
          <el-button type="text">《用户协议》</el-button>
          和
          <el-button type="text">《隐私政策》</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.register-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f7fa;
  padding: 20px;
}

.register-container {
  width: 100%;
  max-width: 460px;
}

.register-box {
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.register-header {
  text-align: center;
  margin-bottom: 30px;
  
  h2 {
    font-size: 24px;
    color: #303133;
    margin-bottom: 10px;
  }
  
  .switch-mode {
    font-size: 14px;
    color: #909399;
    
    .switch-link {
      color: #409EFF;
      text-decoration: none;
      margin-left: 5px;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.verification-code {
  display: flex;
  gap: 10px;
  
  .el-input {
    flex: 1;
  }
  
  .el-button {
    width: 120px;
  }
}

.submit-btn {
  width: 100%;
  padding: 12px 0;
  font-size: 16px;
}

.register-agreement {
  margin-top: 20px;
  text-align: center;
  font-size: 12px;
  color: #909399;
  
  .el-button--text {
    padding: 0;
    font-size: 12px;
  }
}

:deep(.el-form-item__label) {
  padding-bottom: 8px;
  line-height: 1;
}

:deep(.el-input__inner) {
  height: 40px;
}

:deep(.el-form-item) {
  margin-bottom: 20px;
}

:deep(.el-form-item__error) {
  padding-top: 4px;
}
</style>

<script>
import { register, login,sendVerifyCode } from '@/api/auth'

export default {
  name: 'Register',
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.formData.confirmPassword !== '') {
          this.$refs.form.validateField('confirmPassword')
        }
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.formData.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    const validatePhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入手机号'))
      } else if (!/^1[3-9]\d{9}$/.test(value)) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }

    return {
      loading: false,
      cooldown: 0,
      formData: {
        type: 'web',
        phone: '',
        password: '',
        confirmPassword: '',
        verify: ''
      },
      rules: {
        phone: [
          { required: true, validator: validatePhone, trigger: 'blur' }
        ],
        password: [
          { required: true, validator: validatePass, trigger: 'blur' },
          { min: 6, message: '密码长度不能小于6位', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, validator: validatePass2, trigger: 'blur' }
        ],
        verify: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { len: 6, message: '验证码长度应为6位', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate()
        this.loading = true

        // 调用注册接口
        const registerRes = await register({
          type: 'web',
          phone: this.formData.phone,
          verify: this.formData.verify,
          password: this.formData.password
        })

        if (registerRes.code === 1) {
          // 注册成功后自动登录
          const loginRes = await login({
            type: 'web',
            phone: this.formData.phone,
            password: this.formData.password
          })

          if (loginRes.code === 1) {
            // 保存登录信息
            localStorage.setItem('token', loginRes.data.token)
            localStorage.setItem('userInfo', JSON.stringify(loginRes.data.userInfo))

            this.$message.success('注册成功并已自动登录')
            
            // 获取重定向地址
            const redirect = this.$route.query.redirect
            if (redirect) {
              // 如果有重定向地址，跳转回去
              this.$router.push(redirect)
            } else {
              // 否则跳转到首页
              this.$router.push('/')
            }
          } else {
            this.$message.error('自动登录失败，请手动登录')
            this.$router.push('/login')
          }
        } else {
          this.$message.error(registerRes.message || '注册失败，请重试')
        }
      } catch (error) {
        console.error('注册失败：', error)
        this.$message.error(error.message || '注册失败，请重试')
      } finally {
        this.loading = false
      }
    },
    
    async sendVerificationCode() {
      if (!this.formData.phone) {
        this.$message.warning('请先输入手机号')
        return
      }
      try {
        const data = {
          phone: this.formData.phone,
          type: 'register'
        }
        // 调用发送验证码接口
        const res = await sendVerifyCode(data)
        if (res.code === 1) { // 假设 1 是成功状态码
          // 开始倒计时
          this.cooldown = 60
          const timer = setInterval(() => {
            this.cooldown--
            if (this.cooldown <= 0) {
              clearInterval(timer)
            }
          }, 1000)
          
          this.$message.success('验证码已发送')
        } else {
          this.$message.error(res.info || '验证码发送失败')
        }
      } catch (error) {
        console.error('验证码发送失败：', error)
        this.$message.error(error.message || '验证码发送失败')
      }
    }
  }
}
</script> 