<template>
  <div>
    <el-tabs type="border-card" v-model="activeName">
      <el-tab-pane label="国际港澳台" name="international">
        <div class="flight-search-section" :class="{ 'is-disabled': disabled }">
              <div class="search-box">
                <div class="trip-type">
                  <el-radio-group v-model="tripType" role="radiogroup" :disabled="disabled">
                    <el-radio 
                      v-for="option in tripOptions" 
                      :key="option.value"
                      :label="option.value"
                      :aria-label="option.label"
                    >
                      {{ option.label }}
                    </el-radio>
                  </el-radio-group>
                </div>

                <div v-if="tripType === 'multiCity'" class="multi-city-form">
                  <div v-for="(trip, index) in multiCityTrips" :key="index" class="trip-row">
                    <div class="trip-number">{{ index + 1 }}</div>
                    <div class="city-inputs">
                      <div class="from-city">
                        <CitySelector
                          :disabled="disabled"
                          :initial-value="trip.fromCity"
                          :type="activeName"
                          @city-selected="handleMultiCitySelect(index, 'fromCity')"
                        />
                      </div>
                      <div class="switch-cities" @click="switchMultiCities(index)">
                        <i class="el-icon-refresh"></i>
                      </div>
                      <div class="to-city">
                        <CitySelector
                          :disabled="disabled"
                          :initial-value="trip.toCity"
                          :type="activeName"
                          @city-selected="handleMultiCitySelect(index, 'toCity')"
                        />
                      </div>
                      <div class="date-input">
                        <el-date-picker
                          :disabled="disabled"
                          v-model="trip.date"
                          type="date"
                          placeholder="出发日期"
                          :picker-options="dateOptions">
                        </el-date-picker>
                      </div>
                      <div v-if="index > 0" class="remove-trip" @click="removeTrip(index)">
                        <i class="el-icon-close"></i>
                      </div>
                    </div>
                  </div>
                  
                  <div class="add-trip" v-if="multiCityTrips.length < 6">
                    <el-button type="text" @click="addTrip">
                      <i class="el-icon-plus"></i> 再加一程
                    </el-button>
                  </div>

                  <div class="bottom-options">
                    <div class="passenger-cabin">
                      <el-popover
                        v-model="showPassengerPopover"
                        placement="bottom"
                        width="300"
                        trigger="click"
                      >
                        <div class="passenger-selector">
                          <div class="passenger-type" v-for="(type, index) in passengerTypes" :key="index">
                            <div class="type-info">
                              <div class="type-name">{{ type.label }}</div>
                              <div class="type-desc">{{ type.desc }}</div>
                            </div>
                            <div class="type-counter">
                              <el-button 
                                size="mini" 
                                icon="el-icon-minus" 
                                :disabled="type.count <= type.min"
                                @click="updatePassenger(type, -1)"
                              ></el-button>
                              <span class="count">{{ type.count }}</span>
                              <el-button 
                                size="mini" 
                                icon="el-icon-plus" 
                                :disabled="type.count >= type.max || getTotalPassengers() >= 9"
                                @click="updatePassenger(type, 1)"
                              ></el-button>
                            </div>
                          </div>
                          <div class="passenger-tips">
                            最多选择9位乘客
                          </div>
                        </div>
                        <div slot="reference" class="passenger-input-wrapper">
                          <el-input
                            readonly
                            :value="passengersText"
                            class="passenger-select"
                            suffix-icon="el-icon-arrow-down"
                          >
                          </el-input>
                        </div>
                      </el-popover>
                      
                      <el-select v-model="cabinType" placeholder="经济舱" class="cabin-select">
                        <el-option
                          v-for="option in cabinOptions"
                          :key="option.value"
                          :label="option.label"
                          :value="option.value"
                        ></el-option>
                      </el-select>
                    </div>
                    <el-button type="primary" class="search-btn" @click="ticketShoppingV2">
                      搜索
                    </el-button>
                  </div>
                </div>

                <div v-else class="normal-form">
                  <div class="main-inputs">
                    <div class="city-inputs">
                      <div class="from-city">
                        <CitySelector
                          :disabled="disabled"
                          :initial-value="fromCity"
                          :type="activeName"
                          @city-selected="handleFromCitySelect"
                        />
                      </div>
                      <div class="switch-cities" @click="switchCities">
                        <i class="el-icon-refresh"></i>
                      </div>
                      <div class="to-city">
                        <CitySelector
                          :disabled="disabled"
                          :initial-value="toCity"
                          :type="activeName"
                          @city-selected="handleToCitySelect"
                        />
                      </div>
                    </div>

                    <div class="date-inputs">
                      <el-date-picker
                        v-model="departDate"
                        type="date"
                        placeholder="出发日期"
                        :picker-options="dateOptions">
                      </el-date-picker>
                      <el-date-picker
                        v-if="tripType === 'roundTrip'"
                        v-model="returnDate"
                        type="date"
                        placeholder="返回日期"
                        :picker-options="dateOptions">
                      </el-date-picker>
                    </div>

                    <div class="passenger-cabin">
                      <el-popover
                        v-model="showPassengerPopover"
                        placement="bottom"
                        width="300"
                        trigger="click"
                      >
                        <div class="passenger-selector">
                          <div class="passenger-type" v-for="(type, index) in passengerTypes" :key="index">
                            <div class="type-info">
                              <div class="type-name">{{ type.label }}</div>
                              <div class="type-desc">{{ type.desc }}</div>
                            </div>
                            <div class="type-counter">
                              <el-button 
                                size="mini" 
                                icon="el-icon-minus" 
                                :disabled="type.count <= type.min"
                                @click="updatePassenger(type, -1)"
                              ></el-button>
                              <span class="count">{{ type.count }}</span>
                              <el-button 
                                size="mini" 
                                icon="el-icon-plus" 
                                :disabled="type.count >= type.max || getTotalPassengers() >= 9"
                                @click="updatePassenger(type, 1)"
                              ></el-button>
                            </div>
                          </div>
                          <div class="passenger-tips">
                            最多选择9位乘客
                          </div>
                        </div>
                        <div slot="reference" class="passenger-input-wrapper">
                          <el-input readonly :value="passengersText" class="passenger-select"  suffix-icon="el-icon-arrow-down" >
                          </el-input>
                        </div>
                      </el-popover>
                      
                      <el-select v-model="cabinType" placeholder="经济舱" class="cabin-select">
                        <el-option
                          v-for="option in cabinOptions"
                          :key="option.value"
                          :label="option.label"
                          :value="option.value"
                        ></el-option>
                      </el-select>
                    </div>

                    <div class="flight-preferences">
                      <el-select v-model="isDirection" placeholder="飞行偏好" class="direction-select">
                        <el-option
                          v-for="option in directionOptions"
                          :key="option.value"
                          :label="option.label"
                          :value="option.value"
                        ></el-option>
                      </el-select>
                    </div>

                    <el-button type="primary" class="search-btn" @click="ticketShoppingV2">
                      搜索
                    </el-button>
                  </div>
                </div>
              </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="国内机票" name="inChina">
        <div class="flight-search-section" :class="{ 'is-disabled': disabled }">
              <div class="search-box">
                <div class="trip-type">
                  <el-radio-group v-model="tripType" role="radiogroup" :disabled="disabled">
                    <el-radio 
                      v-for="option in tripOptions" 
                      :key="option.value"
                      :label="option.value"
                      :aria-label="option.label"
                    >
                      {{ option.label }}
                    </el-radio>
                  </el-radio-group>
                </div>

                <div v-if="tripType === 'multiCity'" class="multi-city-form">
                  <div v-for="(trip, index) in multiCityTrips" :key="index" class="trip-row">
                    <div class="trip-number">{{ index + 1 }}</div>
                    <div class="city-inputs">
                      <div class="from-city">
                        <CitySelector
                          :disabled="disabled"
                          :initial-value="trip.fromCity"
                          :type="activeName"
                          @city-selected="handleMultiCitySelect(index, 'fromCity')"
                        />
                      </div>
                      <div class="switch-cities" @click="switchMultiCities(index)">
                        <i class="el-icon-refresh"></i>
                      </div>
                      <div class="to-city">
                        <CitySelector
                          :disabled="disabled"
                          :initial-value="trip.toCity"
                          :type="activeName"
                          @city-selected="handleMultiCitySelect(index, 'toCity')"
                        />
                      </div>
                      <div class="date-input">
                        <el-date-picker
                          :disabled="disabled"
                          v-model="trip.date"
                          type="date"
                          placeholder="出发日期"
                          :picker-options="dateOptions">
                        </el-date-picker>
                      </div>
                      <div v-if="index > 0" class="remove-trip" @click="removeTrip(index)">
                        <i class="el-icon-close"></i>
                      </div>
                    </div>
                  </div>
                  
                  <div class="add-trip" v-if="multiCityTrips.length < 6">
                    <el-button type="text" @click="addTrip">
                      <i class="el-icon-plus"></i> 再加一程
                    </el-button>
                  </div>

                  <div class="bottom-options">
                    <div class="passenger-cabin">
                      <el-popover
                        v-model="showPassengerPopover"
                        placement="bottom"
                        width="300"
                        trigger="click"
                      >
                        <div class="passenger-selector">
                          <div class="passenger-type" v-for="(type, index) in passengerTypes" :key="index">
                            <div class="type-info">
                              <div class="type-name">{{ type.label }}</div>
                              <div class="type-desc">{{ type.desc }}</div>
                            </div>
                            <div class="type-counter">
                              <el-button 
                                size="mini" 
                                icon="el-icon-minus" 
                                :disabled="type.count <= type.min"
                                @click="updatePassenger(type, -1)"
                              ></el-button>
                              <span class="count">{{ type.count }}</span>
                              <el-button 
                                size="mini" 
                                icon="el-icon-plus" 
                                :disabled="type.count >= type.max || getTotalPassengers() >= 9"
                                @click="updatePassenger(type, 1)"
                              ></el-button>
                            </div>
                          </div>
                          <div class="passenger-tips">
                            最多选择9位乘客
                          </div>
                        </div>
                        <div slot="reference" class="passenger-input-wrapper">
                          <el-input
                            readonly
                            :value="passengersText"
                            class="passenger-select"
                            suffix-icon="el-icon-arrow-down"
                          >
                          </el-input>
                        </div>
                      </el-popover>
                      
                      <el-select v-model="cabinType" placeholder="经济舱" class="cabin-select">
                        <el-option
                          v-for="option in cabinOptions"
                          :key="option.value"
                          :label="option.label"
                          :value="option.value"
                        ></el-option>
                      </el-select>
                    </div>
                    <el-button type="primary" class="search-btn" @click="ticketShoppingV2">
                      搜索
                    </el-button>
                  </div>
                </div>

                <div v-else class="normal-form">
                  <div class="main-inputs">
                    <div class="city-inputs">
                      <div class="from-city">
                        <CitySelector
                          :disabled="disabled"
                          :initial-value="fromCity"
                          :type="activeName"
                          @city-selected="handleFromCitySelect"
                        />
                      </div>
                      <div class="switch-cities" @click="switchCities">
                        <i class="el-icon-refresh"></i>
                      </div>
                      <div class="to-city">
                        <CitySelector
                          :disabled="disabled"
                          :initial-value="toCity"
                          :type="activeName"
                          @city-selected="handleToCitySelect"
                        />
                      </div>
                    </div>

                    <div class="date-inputs">
                      <el-date-picker
                        v-model="departDate"
                        type="date"
                        placeholder="出发日期"
                        :picker-options="dateOptions">
                      </el-date-picker>
                      <el-date-picker
                        v-if="tripType === 'roundTrip'"
                        v-model="returnDate"
                        type="date"
                        placeholder="返回日期"
                        :picker-options="dateOptions">
                      </el-date-picker>
                    </div>

                    <div class="passenger-cabin">
                      <el-popover
                        v-model="showPassengerPopover"
                        placement="bottom"
                        width="300"
                        trigger="click"
                      >
                        <div class="passenger-selector">
                          <div class="passenger-type" v-for="(type, index) in passengerTypes" :key="index">
                            <div class="type-info">
                              <div class="type-name">{{ type.label }}</div>
                              <div class="type-desc">{{ type.desc }}</div>
                            </div>
                            <div class="type-counter">
                              <el-button 
                                size="mini" 
                                icon="el-icon-minus" 
                                :disabled="type.count <= type.min"
                                @click="updatePassenger(type, -1)"
                              ></el-button>
                              <span class="count">{{ type.count }}</span>
                              <el-button 
                                size="mini" 
                                icon="el-icon-plus" 
                                :disabled="type.count >= type.max || getTotalPassengers() >= 9"
                                @click="updatePassenger(type, 1)"
                              ></el-button>
                            </div>
                          </div>
                          <div class="passenger-tips">
                            最多选择9位乘客
                          </div>
                        </div>
                        <div slot="reference" class="passenger-input-wrapper">
                          <el-input readonly :value="passengersText" class="passenger-select"  suffix-icon="el-icon-arrow-down" >
                          </el-input>
                        </div>
                      </el-popover>
                      
                      <el-select v-model="cabinType" placeholder="经济舱" class="cabin-select">
                        <el-option
                          v-for="option in cabinOptions"
                          :key="option.value"
                          :label="option.label"
                          :value="option.value"
                        ></el-option>
                      </el-select>
                    </div>

                    <div class="flight-preferences">
                      <el-select v-model="isDirection" placeholder="飞行偏好" class="direction-select">
                        <el-option
                          v-for="option in directionOptions"
                          :key="option.value"
                          :label="option.label"
                          :value="option.value"
                        ></el-option>
                      </el-select>
                    </div>

                    <el-button type="primary" class="search-btn" @click="ticketShoppingV2">
                      搜索
                    </el-button>
                  </div>
                </div>
              </div>
        </div>
      </el-tab-pane>
    </el-tabs>

    <!-- 添加航班列表显示 -->
    <flight-list 
      v-if="searchResult && searchResult.length"
      :flight-list="searchResult"
      :serial-number="serialNumber"
      @select-flight="handleSelectFlight"
    />
  </div>
</template>

<script>
import * as flightApi from '@/api/flight'
import CitySelector from '@/components/CitySelector.vue'
import FlightList from '@/components/FlightList.vue'

export default {
  name: 'FlightSearchSection',
  components: {
    CitySelector,
    FlightList
  },
  props: {
    mode: {
      type: String,
      default: 'search'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    // 获取当前日期和明天的日期
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    const afterTomorrow = new Date(today)
    afterTomorrow.setDate(afterTomorrow.getDate() + 2)

    return {
      activeName: 'international',
      tripType: 'oneWay',
      tripOptions: [
        { label: '单程', value: 'oneWay' },
        { label: '往返', value: 'roundTrip' },
        { label: '多程', value: 'multiCity' }
      ],
      // 默认城市和城市代码
      fromCity: '',
      toCity: '',
      fromCityCode: '',
      toCityCode: '',
      // 设置默认日期
      departDate: tomorrow,
      returnDate: afterTomorrow,
      // 多程认值
      multiCityTrips: [
        {
          fromCity: '广州',
          fromCityCode: 'CAN',
          toCity: '新加坡',
          toCityCode: 'SIN',
          date: tomorrow
        },
        {
          fromCity: '新加坡',
          fromCityCode: 'SIN',
          toCity: '广州',
          toCityCode: 'CAN',
          date: afterTomorrow
        }
      ],
      // 乘客默认值
      passengerTypes: [
        { 
          type: 'adult',
          label: '成人', 
          desc: '12周岁及以上',
          count: 1,
          min: 1,
          max: 9
        },
        { 
          type: 'child',
          label: '儿童', 
          desc: '2-12周岁',
          count: 0,
          min: 0,
          max: 4
        },
        { 
          type: 'infant',
          label: '婴儿', 
          desc: '14天-2周��',
          count: 0,
          min: 0,
          max: 2
        }
      ],
      showPassengerPopover: false,
      cabinType: '0',  // 改回默认经济舱数字编码
      cabinOptions: [
        { label: '经济舱', value: '0' },
        { label: '豪华经济舱', value: '1' },
        { label: '商务舱', value: '3' },
        { label: '头等舱', value: '4' }
      ],
      isDirection: 0,  // 默认不限
      directionOptions: [
        { value: 0, label: '不限' },
        { value: 1, label: '直飞' },
        { value: 2, label: '最多中转1次' },
        { value: 3, label: '最大中转2次' }
      ],
      dateOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7
        }
      },
      searchResult: null,
      serialNumber: '',
      loading: false
    }
  },
  computed: {
    passengersText() {
      return this.getPassengerSummary()
    }
  },
  created() {
    // 根据当前 tab 设置默认城市
    this.setDefaultCities(this.activeName)
  },
  methods: {
    setDefaultCities(tab) {
      if (tab === 'inChina') {
        this.fromCity = '深圳'
        this.toCity = '上海'
        this.fromCityCode = 'SZX'
        this.toCityCode = 'SHA'
      } else {
        this.fromCity = '广州'
        this.toCity = '新加坡'
        this.fromCityCode = 'CAN'
        this.toCityCode = 'SIN'
      }
    },
    handleFromCitySelect(city) {
      this.fromCity = city.label;
      this.fromCityCode = city.value;
    },
    handleToCitySelect(city) {
      this.toCity = city.label;
      this.toCityCode = city.value;
    },
    handleMultiCitySelect(index, type) {
      return (city) => {
        this.multiCityTrips[index][type] = city.label;
        this.multiCityTrips[index][`${type}Code`] = city.value;
      }
    },
    switchMultiCities(index) {
      const trip = this.multiCityTrips[index]
      const tempCity = trip.fromCity
      const tempCode = trip.fromCityCode
      trip.fromCity = trip.toCity
      trip.fromCityCode = trip.toCityCode
      trip.toCity = tempCity
      trip.toCityCode = tempCode
      
      // 强制更新 CitySelector 组件
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    addTrip() {
      if (this.multiCityTrips.length < 6) {
        const lastTrip = this.multiCityTrips[this.multiCityTrips.length - 1]
        this.multiCityTrips.push({
          fromCity: lastTrip.toCity || '',
          fromCityCode: lastTrip.toCityCode || '',
          toCity: '',
          toCityCode: '',
          date: ''
        })
      }
    },
    removeTrip(index) {
      this.multiCityTrips.splice(index, 1)
    },
    async ticketShoppingV2() {
      try {
        this.$emit('search-start')
        let res
        
        if (this.activeName === 'inChina') {
          // 构建国内航班搜索参数
          const domesticParams = {
            origin_destinations: [],
            physical_cabin: (() => {
              // 根据选择的数字编码转换为对应的舱位代码
              switch(this.cabinType) {
                case '4': return 'F' // 头等舱
                case '3': return 'C' // 商务舱
                case '1': return 'W' // 豪华经济舱
                case '0': 
                default: return 'Y' // 经济舱
              }
            })(),
            is_search_gwbook: false,
            is_search_rule: true,
            apply_result: true,
            is_get_added_value: false,
            passengers: []
          }

          // 构建乘客信息
          const passengerMap = {
            adult: 'ADT',
            child: 'CHD',
            infant: 'INF'
          }

          // 动态添加乘客信息
          this.passengerTypes.forEach(type => {
            if (type.count > 0) {
              domesticParams.passengers.push({
                passenger_type: passengerMap[type.type],
                passenger_count: type.count
              })
            }
          })

          // 据行程类型构建 origin_destinations
          if (this.tripType === 'multiCity') {
            domesticParams.origin_destinations = this.multiCityTrips
              .filter(trip => trip.fromCityCode && trip.toCityCode && trip.date)
              .map(trip => ({
                departure_city: trip.fromCityCode,
                arrival_city: trip.toCityCode,
                departure_date: this.formatDate(trip.date),
                earlies_time: "00:00",
                latest_time: "23:59"
              }))
          } else {
            if (!this.fromCityCode || !this.toCityCode || !this.departDate) {
              this.$message.error('请完善出发地、目的地和出发日期')
              return
            }

            domesticParams.origin_destinations.push({
              departure_city: this.fromCityCode,
              arrival_city: this.toCityCode,
              departure_date: this.formatDate(this.departDate),
              earlies_time: "00:00",
              latest_time: "23:59"
            })
            
            if (this.tripType === 'roundTrip') {
              if (!this.returnDate) {
                this.$message.error('请选择程日期')
                return
              }
              domesticParams.origin_destinations.push({
                departure_city: this.toCityCode,
                arrival_city: this.fromCityCode,
                departure_date: this.formatDate(this.returnDate),
                earlies_time: "00:00",
                latest_time: "23:59"
              })
            }
          }

          if (!domesticParams.origin_destinations.length) {
            this.$message.error('请完善航程信息')
            return
          }

          // 调用国内航班搜索接口
          res = await flightApi.domesticTicketShopping(domesticParams)
          
          // 处理国内航班搜索结果
          if (res.code === 1 && res.data && res.data.flightList) {
            // 转换数据结构以适配现有的 FlightList 组件
            const formattedResult = {
              serialNumber: res.data.serialNumber,
              flightList: res.data.flightList.map(flight => ({
                flight_id: flight.flight_id,
                cabin_fares: flight.cabin_fares,
                trips: [{
                  origin: flight.trips[0].origin,
                  arrival: flight.trips[0].arrival,
                  flights: flight.trips[0].flights
                }]
              }))
            }
            this.searchResult = formattedResult.flightList
            this.serialNumber = res.data.serialNumber
            this.$emit('search-result', formattedResult)
          } else {
            throw new Error(res.info || '未找到符合条件的航班')
          }
        } else {
          // 国际航班搜索参数和逻辑保持不变
          const baseParams = {
            isDirection: this.isDirection,
            airline: 'CZ',
            cabinType: parseInt(this.cabinType),
            adultNumber: this.passengerTypes.find(t => t.type === 'adult').count,
            childNumber: this.passengerTypes.find(t => t.type === 'child').count,
            infantNumber: this.passengerTypes.find(t => t.type === 'infant').count,
            odList: []
          }

          if (this.tripType === 'multiCity') {
            baseParams.odList = this.multiCityTrips
              .filter(trip => trip.fromCityCode && trip.toCityCode && trip.date)
              .map(trip => ({
                departureCode: trip.fromCityCode,
                destinationCode: trip.toCityCode,
                departureDate: this.formatDate(trip.date)
              }))
          } else {
            baseParams.odList.push({
              departureCode: this.fromCityCode,
              destinationCode: this.toCityCode,
              departureDate: this.formatDate(this.departDate)
            })
            
            if (this.tripType === 'roundTrip') {
              baseParams.odList.push({
                departureCode: this.toCityCode,
                destinationCode: this.fromCityCode,
                departureDate: this.formatDate(this.returnDate)
              })
            }
          }

          // 调用国际航班搜索接口并保持原有的结果处理方式
          res = await flightApi.ticketShoppingV2(baseParams)
          this.searchResult = res
          this.$emit('search-result', res)
        }
      } catch (error) {
        console.error('搜索失败：', error)
        this.$message.error(error.message || '搜索失败，请重试')
      } finally {
        this.$emit('search-end')
      }
    },
    switchCities() {
      const tempCity = this.fromCity
      const tempCode = this.fromCityCode
      this.fromCity = this.toCity
      this.fromCityCode = this.toCityCode
      this.toCity = tempCity
      this.toCityCode = tempCode
      
      // 强制更新 CitySelector 组件
      this.$nextTick(() => {
        this.$forceUpdate()
      })
    },
    updatePassenger(type, delta) {
      const newCount = type.count + delta
      if (newCount >= type.min && newCount <= type.max && this.getTotalPassengers() + delta <= 9) {
        type.count = newCount
        this.$emit('passenger-change', this.passengerTypes)
      }
    },
    getTotalPassengers() {
      return this.passengerTypes.reduce((sum, type) => sum + type.count, 0)
    },
    getPassengerSummary() {
      const summary = this.passengerTypes
        .filter(type => type.count > 0)
        .map(type => `${type.count}${type.label}`)
        .join(' ')
      return summary || '请选择乘客'
    },
    formatDate(date) {
      if (!date) return ''
      const d = new Date(date)
      return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`
    },
    handleDepartureCity(city) {
      this.departureCity = city;
    },
    handleSelectFlight(flightData) {
      // 将选中的航班信息传递给父组件
      this.$emit('select-flight', flightData)
    },
    handleTabClick(tab) {
      // 切换 tab 时更新默认城市和触发搜索
      if (tab.name === 'inChina') {
        this.fromCity = '深圳'
        this.toCity = '上海'
        this.fromCityCode = 'SZX'
        this.toCityCode = 'SHA'
      } else {
        this.fromCity = '广州'
        this.toCity = '新加坡'
        this.fromCityCode = 'CAN'
        this.toCityCode = 'SIN'
      }
      
      // 触发 tab 切换事件
      this.$emit('tab-change', tab.name)
      
      // 自动触发搜索
      this.$nextTick(() => {
        this.ticketShoppingV2()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.flight-search-section {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  &.is-disabled {
    opacity: 0.7;
    cursor: not-allowed;
    pointer-events: none;

    :deep(.el-input__inner),
    :deep(.el-select),
    :deep(.el-button),
    :deep(.el-radio-group) {
      cursor: not-allowed;
    }
  }
}

.trip-type {
  margin-bottom: 20px;

  :deep(.el-radio) {
    margin-right: 20px;
    
    &:last-child {
      margin-right: 0;
    }

    .el-radio__input {
      .el-radio__inner {
        border-color: #DCDFE6;
      }
    }

    &.is-checked {
      .el-radio__input {
        .el-radio__inner {
          border-color: #409EFF;
          background: #409EFF;
        }
      }
      .el-radio__label {
        color: #409EFF;
      }
    }
  }
}

.normal-form {
  .main-inputs {
    display: flex;
    align-items: center;
    gap: 15px;
    
    .city-inputs {
      display: flex;
      align-items: center;
      gap: 10px;
      
      .from-city, .to-city {
        position: relative;
        width: 120px;
        
        :deep(.el-input__inner) {
          cursor: pointer;
        }
      }
    }
    
    .date-inputs {
      display: flex;
      gap: 10px;
      
      :deep(.el-date-editor) {
        width: 135px;
      }
    }
    
    .passenger-cabin {
      display: flex;
      gap: 10px;
      
      .passenger-select {
        width: 180px;
      }
      
      .cabin-select {
        width: 100px;
      }
    }

    .flight-preferences {
      .direction-select {
        width: 120px;
      }
    }
    
    .search-btn {
      width: 100px;
      height: 36px;
    }
  }
}

.multi-city-form {
  .trip-row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    
    .trip-number {
      width: 24px;
      height: 24px;
      background: #f0f2f5;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #666;
      font-size: 14px;
      margin-right: 15px;
      flex-shrink: 0;
    }
    
    .city-inputs {
      display: flex;
      align-items: center;
      gap: 15px;
      flex: 1;
      
      .from-city, .to-city {
        width: 180px;
        position: relative;
      }
      
      .date-input {
        width: 160px;
        flex-shrink: 0;
        
        :deep(.el-input__inner) {
          cursor: pointer;
        }
      }

      .switch-cities {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: #f5f7fa;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        flex-shrink: 0;
        
        &:hover {
          background: #e4e7ed;
        }
        
        i {
          color: #409EFF;
          font-size: 18px;
        }
      }

      .remove-trip {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #999;
        flex-shrink: 0;
        
        &:hover {
          color: #666;
        }
      }
    }
  }
  
  .add-trip {
    padding-left: 39px;
    margin: 20px 0;
  }
  
  .bottom-options {
    display: flex;
    align-items: center;
    gap: 15px;
    padding-left: 39px;
    margin-top: 20px;
    
    .passenger-cabin {
      display: flex;
      gap: 15px;
      flex: 1;
      
      .passenger-select {
        width: 180px;
      }
      
      .cabin-select {
        width: 120px;
      }
    }
    
    .search-btn {
      width: 120px;
      height: 36px;
    }
  }
}

:deep(.el-date-editor.el-input) {
  width: 160px;
}

:deep(.el-input__inner) {
  height: 36px;
  line-height: 36px;
}

:deep(.el-button--text) {
  color: #2681ff;
  
  i {
    margin-right: 5px;
  }
}

.passenger-selector {
  padding: 10px;
  
  .passenger-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #EBEEF5;
    
    &:last-child {
      border-bottom: none;
    }
    
    .type-info {
      .type-name {
        font-size: 14px;
        color: #333;
        margin-bottom: 4px;
      }
      
      .type-desc {
        font-size: 12px;
        color: #999;
      }
    }
    
    .type-counter {
      display: flex;
      align-items: center;
      gap: 10px;
      
      .count {
        width: 20px;
        text-align: center;
        font-size: 14px;
      }
    }
  }
  
  .passenger-tips {
    margin-top: 10px;
    font-size: 12px;
    color: #999;
    text-align: center;
  }
}

.passenger-select {
  :deep(.el-input__inner) {
    padding-right: 30px;
  }
  
  :deep(.el-input__suffix) {
    right: 5px;
    transition: transform 0.3s;
    
    .el-icon-arrow-down {
      font-size: 12px;
      color: #909399;
    }
  }
  
  &:hover {
    :deep(.el-input__suffix) {
      color: #409EFF;
    }
  }
}

:deep(.el-popover__reference-wrapper) {
  &.focusing {
    .el-input__suffix {
      transform: rotate(180deg);
    }
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-content {
  text-align: center;
}

.loading-icon {
  font-size: 48px;
  color: #409EFF;
  margin-bottom: 20px;
  
  i {
    animation: rotating 2s linear infinite;
  }
}

.loading-text {
  font-size: 16px;
  color: #606266;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style> 