<template>
  <div class="login-page">
    <div class="login-container">
      <div class="login-box">
        <div class="login-header">
          <h2>登录</h2>
          <!-- <div class="switch-mode">
            还没有账号？
            <router-link to="/register">立即注册</router-link>
          </div> -->
        </div>

        <el-form 
          ref="form" 
          :model="formData" 
          :rules="rules"
          label-position="top"
        >
          <el-form-item prop="phone" label="手机号">
            <el-input 
              v-model="formData.phone"
              prefix-icon="el-icon-mobile-phone"
              placeholder="请输入手机号"
            />
          </el-form-item>

          <el-form-item prop="password" label="密码">
            <el-input 
              v-model="formData.password"
              prefix-icon="el-icon-lock"
              type="password"
              placeholder="请输入密码"
              show-password
            />
          </el-form-item>

          <el-form-item>
            <div class="login-options">
              <el-checkbox v-model="rememberMe">记住我</el-checkbox>
              <router-link to="/forgot-password" class="forgot-link">忘记密码？</router-link>
            </div>
          </el-form-item>

          <el-form-item>
            <el-button 
              type="primary" 
              class="submit-btn" 
              :loading="loading"
              @click="handleSubmit"
            >
              登录
            </el-button>
          </el-form-item>
        </el-form>

        <div class="other-login">
          <div class="divider">
            <span>其他登录方式</span>
          </div>
          <div class="login-methods">
            <i class="el-icon-chat-dot-round" title="微信登录"></i>
            <i class="el-icon-mobile-phone" title="手机号登录"></i>
            <i class="el-icon-message" title="邮箱登录"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from '@/api/auth'

export default {
  name: 'Login',
  data() {
    return {
      loading: false,
      rememberMe: false,
      formData: {
        type: 'web',
        phone: '',
        password: ''
      },
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, message: '密码长度不能小于6位', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$refs.form.validate()
        this.loading = true

        console.log('登录信息：', this.formData)
        // 调用登录接口
        const res = await login({
          type: 'web',
          phone: this.formData.phone,
          password: this.formData.password
        })
        
        if (res.code === 1) {
          // 修改 token 的保存格式，添加 Bearer 前缀
          localStorage.setItem('token', `Bearer ${res.data.token}`)
          localStorage.setItem('userInfo', JSON.stringify({
            phone: this.formData.phone,
            ...res.data.user
          }))
          
          this.$message.success('登录成功')
          
          // 获取重定向地址
          const redirect = this.$route.query.redirect
          
          if (redirect) {
            // 如果有重定向地址，跳转回去
            this.$router.push(redirect)
          } else {
            // 否则跳转到首页
            this.$router.push('/')
          }
        } else {
          this.$message.error(res.message || '登录失败，请重试')
        }
      } catch (error) {
        console.error('登录失败：', error)
        this.$message.error(error.message || '登录失败，请重试')
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f7fa;
  padding: 20px;
}

.login-container {
  width: 100%;
  max-width: 460px;
}

.login-box {
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.login-header {
  text-align: center;
  margin-bottom: 30px;
  
  h2 {
    font-size: 24px;
    color: #303133;
    margin-bottom: 10px;
  }
  
  .switch-mode {
    font-size: 14px;
    color: #909399;
    
    span {
      color: #409EFF;
      cursor: pointer;
      margin-left: 5px;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.verification-code {
  display: flex;
  gap: 10px;
  
  .el-input {
    flex: 1;
  }
  
  .el-button {
    width: 120px;
  }
}

.login-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.submit-btn {
  width: 100%;
  padding: 12px 0;
  font-size: 16px;
}

.other-login {
  margin-top: 30px;
  
  .divider {
    display: flex;
    align-items: center;
    color: #909399;
    font-size: 14px;
    margin-bottom: 20px;
    
    &::before,
    &::after {
      content: '';
      flex: 1;
      height: 1px;
      background: #DCDFE6;
    }
    
    span {
      padding: 0 15px;
    }
  }
  
  .login-methods {
    display: flex;
    justify-content: center;
    gap: 30px;
    
    i {
      font-size: 24px;
      color: #909399;
      cursor: pointer;
      transition: color 0.3s;
      
      &:hover {
        color: #409EFF;
      }
    }
  }
}

:deep(.el-form-item__label) {
  padding-bottom: 8px;
  line-height: 1;
}

:deep(.el-input__inner) {
  height: 40px;
}

:deep(.el-checkbox__label) {
  color: #606266;
}

.forgot-link {
  color: #409EFF;
  text-decoration: none;
  font-size: 14px;
  
  &:hover {
    text-decoration: underline;
  }
}
</style> 