<template>
  <div id="app">
    <TheHeader />
    <NavBar />
    <router-view/>
  </div>
</template>

<script>
import TheHeader from './components/Header.vue'
import NavBar from './components/NavBar.vue'

export default {
  name: 'App',
  components: {
    TheHeader,
    NavBar
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#app {
  min-height: 100vh;
}
</style> 