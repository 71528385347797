import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '@/views/Login.vue'
import Register from '@/views/Register.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: '登录'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      title: '注册'
    }
  },
  {
    path: '/flight',
    name: 'Flight',
    component: () => import('../views/Flight.vue')
  },
  {
    path: '/flight/result',
    name: 'FlightResult',
    component: () => import('@/views/flight/Result.vue')
  },
  {
    path: '/flight/resultnew',
    name: 'FlightResultNew',
    component: () => import('@/views/flight/Resultnew.vue')
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('@/views/Orders.vue'),
    meta: {
      requiresAuth: true,
      title: '我的订单'
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      title: '找回密码'
    }
  },
  {
    path: '/flight/domestic-order',
    component: () => import('@/views/DomesticFlightOrder.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router 