import { httpPost,httpGet } from './http'

// 机票搜索
export function ticketShoppingV2(data) {
  return httpPost('/airTicket/ticketShoppingV2', data)
}

// 国内航班搜索
export function domesticTicketShopping(data) {
  return httpPost('/airTicket/domesticTicketShopping', data)
}

// 退改签查询
export function ticketRuleQueryV2(data) {
  return httpPost('/airTicket/ticketRuleQueryV2', data)
}

// 国内退改签查询
export function domesticShoppingRuleQueryV2(data) {
  return httpPost('/airTicket/domesticShoppingRuleQueryV2', data)
}

// 价格政策查询
export function ticketFarePolicyByTrips(data) {
  return httpPost('/airTicket/ticketFarePolicyByTrips', data)
}

// 订单提交
export function ITOOrderSave(data) {
  return httpPost('/airTicket/ITOOrderSave', data)
}

// 添加订单支付验证接口
export function orderPayVer(data) {
  return httpPost('/airTicket/orderPayVer', data)
} 

// 获取支付方式
export function getPayment() {
  return httpGet('/center/getPayment')
} 


// 发起订单支付
export function checkout(data) {
  return httpPost('/center/checkout', data)
} 

// ��传支付凭证
export function uploadPaymentProof(data) {
  return httpPost('/upload/file', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
} 

// 国内航班价格查询
export function domesticTicketFinanceQueryV2(data) {
  return httpPost('/airTicket/domesticTicketFinanceQueryV2', data)
}