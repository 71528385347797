<template>
  <div>
    <div class="flight-list">
      <div v-for="flight in flightList" :key="flight.flight_id" class="flight-item">
        <!-- 航班信息 -->
        <div class="flight-info" v-for="trip in flight.trips" :key="trip.origin + trip.arrival">
          <div class="airline-info">
            <div v-for="segment in (Array.isArray(trip.flights) ? trip.flights : [trip.flights])" 
              :key="segment.flight_id" 
              class="airline"
            >
              <img 
                :src="`https://static.tripcdn.com/packages/flight/airline-logo/latest/airline/48/${segment.airline.toUpperCase()}.png`" 
                :alt="segment.airline_cnname"
                class="airline-logo"
              >
              <span class="airline-name">{{ segment.airline_cnname }}({{ segment.airline }})</span>
              <span class="flight-number">{{ segment.flight_number }}</span>
              <div class="equipment">{{ segment.equipment }}</div>
            </div>
          </div>

          <div class="time-info">
            <div class="departure">
              <div class="time">{{ getFirstFlight(trip.flights).departure_time }}</div>
              <div class="airport">{{ getFirstFlight(trip.flights).departure_airport_short_cnname }}</div>
              <div class="terminal">{{ getFirstFlight(trip.flights).dep_term }}</div>
            </div>
            
            <div class="flight-duration">
              <div class="duration-line"></div>
              <div class="stop-info" v-if="getStopCount(trip.flights) > 0">
                经停{{ getStopCount(trip.flights) }}次
              </div>
            </div>
            
            <div class="arrival">
              <div class="time">{{ getLastFlight(trip.flights).arrival_time }}</div>
              <div class="airport">{{ getLastFlight(trip.flights).arrival_airport_short_cnname }}</div>
              <div class="terminal">{{ getLastFlight(trip.flights).arr_term }}</div>
            </div>
          </div>
        </div>

        <!-- 舱位信息 -->
        <div class="cabin-list">
          <div v-for="cabin in flight.cabin_fares" :key="cabin.cabin_fare_id" class="cabin-item">
            <div class="cabin-info">
              <span class="cabin-name">{{ cabin.cabin_name }}</span>
              <span class="class-code">({{ cabin.class_code }})</span>
              <span class="seat-info" v-if="cabin.seat">座位: {{ cabin.seat }}</span>
            </div>
            
            <div class="fare-info" v-for="fare in cabin.psg_fares" :key="fare.passenger_type">
              <div class="price">
                <span class="amount">¥{{ fare.total_fare }}</span>
                <span class="discount" v-if="fare.discount">{{ fare.discount }}折</span>
              </div>
              <div class="tax-info">
                <div>机建费：¥{{ fare.airport_tax }}</div>
                <div v-if="fare.oil_tax">燃油费：¥{{ fare.oil_tax }}</div>
              </div>
              <div class="baggage" v-if="cabin.rule_obj.rules[0].baggage_remark">
                行李额：{{ cabin.rule_obj.rules[0].baggage_remark }}
              </div>
            </div>

            <div class="rule-info">
              <el-button 
                type="text" 
                size="small" 
                @click="showRules(flight.flight_id, cabin.cabin_fare_id)"
              >
                退改签规则
              </el-button>
            </div>

            <el-button type="primary" size="small" @click="handleSelect(flight, cabin)">
              选择
            </el-button>
          </div>
        </div>
      </div>
    </div>

    <!-- 退改签规则弹窗 -->
    <el-dialog
      title="退改签规则"
      :visible.sync="ruleDialogVisible"
      width="600px"
    >
      <div v-loading="loadingRules">
        <div v-if="currentRules && currentRules.data && currentRules.data.length" class="rule-details">
          <!-- 签转条件 -->
          <div class="change-conditions" v-if="currentRules.data[0].changeConditions">
            <h4>签转条件</h4>
            <p>{{ currentRules.data[0].changeConditions }}</p>
          </div>

          <div class="rule-section">
            <h4>退票规则</h4>
            <div v-for="(item, index) in currentRules.data[0].ruleItem" :key="index" class="rule-item">
              <div class="time-info">
                <div class="time-condition">{{ item.operatorHourText }}</div>
                <div class="unit" v-if="item.unit">
                  时间单位：{{ item.unit === '10' ? '小时' : '天' }}
                </div>
              </div>
              <div class="fee-info">
                <div class="fee">退票费：¥{{ item.refundPrice }}</div>
                <div class="percent" v-if="item.refund_percent">
                  退票手续费率：{{ item.refund_percent }}%
                </div>
              </div>
            </div>
          </div>

          <div class="rule-section">
            <h4>改签规则</h4>
            <div v-for="(item, index) in currentRules.data[0].ruleItem" :key="index" class="rule-item">
              <div class="time-info">
                <div class="time-condition">{{ item.operatorHourText }}</div>
                <div class="unit" v-if="item.unit">
                  时间单位：{{ item.unit === '10' ? '小时' : '天' }}
                </div>
              </div>
              <div class="fee-info">
                <div class="fee">改签费：¥{{ item.changePrice }}</div>
                <div class="percent" v-if="item.change_percent">
                  改签手续费率：{{ item.change_percent }}%
                </div>
              </div>
            </div>
          </div>

          <!-- 行李额信息 -->
          <div class="baggage-info" v-if="currentRules.data[0].baggageAllowance">
            <h4>行李额</h4>
            <p>{{ currentRules.data[0].baggageAllowance }}</p>
          </div>

          <!-- 备注信息 -->
          <div class="rule-remark" v-if="currentRules.data[0].ruleRemark">
            <h4>备注</h4>
            <p>{{ currentRules.data[0].ruleRemark }}</p>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { domesticShoppingRuleQueryV2, domesticTicketFinanceQueryV2 } from '@/api/flight'

export default {
  name: 'FlightList',
  props: {
    flightList: {
      type: Array,
      required: true
    },
    serialNumber: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      ruleDialogVisible: false,
      loadingRules: false,
      currentRules: null
    }
  },
  methods: {
    async handleSelect(flight, cabin) {
      try {
        // 构建请求参数
        const params = {
          serialNumberList: [this.serialNumber],
          flightIdList: [flight.flight_id],
          cabinFareIdList: [cabin.cabin_fare_id],
          PNR: "",
          is_search_gwbook: false,
          type: 0,
          psgList: this.buildPassengerList(),
          flightList: [{
            airline: flight.trips[0].flights[0].airline,
            arrival_airport: flight.trips[0].flights[0].arrival_airport,
            arrival_date: flight.trips[0].flights[0].arrival_date,
            arrival_time: flight.trips[0].flights[0].arrival_time,
            code_share: flight.trips[0].flights[0].code_share,
            departure_airport: flight.trips[0].flights[0].departure_airport,
            departure_date: flight.trips[0].flights[0].departure_date,
            departure_time: flight.trips[0].flights[0].departure_time,
            equipment: flight.trips[0].flights[0].equipment,
            flight_number: flight.trips[0].flights[0].flight_number,
            cabin: cabin.cabin,
            class_code: cabin.class_code
          }]
        }

        const res = await domesticTicketFinanceQueryV2(params)
          if (res.info === 'success') {
          console.log('res', res)
          // 直接跳转到订单页面
          this.$router.push({
            path: '/flight/domestic-order',
            query: {
              orderData: JSON.stringify({
                flightInfo: flight,
                cabinInfo: cabin,
                financeInfo: res.data,
                passengerTypes: this.$parent.passengerTypes.filter(t => t.count > 0).map(t => ({
                  passengerType: t.type === 'adult' ? '0' : t.type === 'child' ? '1' : '2',
                  passengerCount: t.count
                }))
              })
            }
          })
        } else {
          throw new Error(res.info || '价格查询失败')
        }
      } catch (error) {
        this.$message.error(error.message || '选择航班失败')
      }
    },
    // 构建乘客列表
    buildPassengerList() {
      const passengerTypes = [
        { type: '0', label: '成人' },
        { type: '1', label: '儿童' },
        { type: '2', label: '婴儿' }
      ]
      
      return passengerTypes
        .filter(type => this.$parent.passengerTypes?.find(t => 
          (t.type === 'adult' && type.type === '0') ||
          (t.type === 'child' && type.type === '1') ||
          (t.type === 'infant' && type.type === '2')
        )?.count > 0)
        .map(type => ({
          type: type.type,
          count: String(this.$parent.passengerTypes?.find(t => 
            (t.type === 'adult' && type.type === '0') ||
            (t.type === 'child' && type.type === '1') ||
            (t.type === 'infant' && type.type === '2')
          )?.count || 0)
        }))
    },
    async showRules(flightId, cabinFareId) {
      try {
        this.ruleDialogVisible = true
        this.loadingRules = true
        this.currentRules = null
        
        const res = await domesticShoppingRuleQueryV2({
          shoppingSerialNumber: this.serialNumber,
          flightId,
          cabinFareId
        })
        
        if (res.code === 1) {
          this.currentRules = res
        } else {
          throw new Error(res.info || '获取规则失败')
        }
      } catch (error) {
        this.$message.error(error.message || '获取退改规则失败')
      } finally {
        this.loadingRules = false
      }
    },
    getFirstFlight(flights) {
      if (Array.isArray(flights)) {
        return flights[0] || {}
      }
      return flights || {}
    },
    getLastFlight(flights) {
      if (Array.isArray(flights)) {
        return flights[flights.length - 1] || {}
      }
      return flights || {}
    },
    getStopCount(flights) {
      if (Array.isArray(flights)) {
        return flights.length - 1
      }
      return 0
    }
  }
}
</script>

<style lang="scss" scoped>
.flight-list {
  .flight-item {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 16px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);

    .flight-info {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid #ebeef5;

      .airline-info {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .airline {
          display: flex;
          align-items: center;
          margin-bottom: 8px;

          .airline-logo {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            object-fit: contain;
          }

          .airline-name {
            margin-right: 8px;
            font-weight: bold;
          }

          .flight-number {
            color: #606266;
            margin-right: 8px;
          }
        }

        .equipment {
          color: #999;
          font-size: 14px;
        }
      }

      .time-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;

        .departure, .arrival {
          .time {
            font-size: 24px;
            font-weight: bold;
          }

          .airport {
            margin: 4px 0;
            color: #666;
          }

          .terminal {
            color: #999;
            font-size: 12px;
          }
        }

        .flight-duration {
          flex: 1;
          padding: 0 20px;
          position: relative;

          .duration-line {
            height: 2px;
            background: #dcdfe6;
            position: relative;

            &::before, &::after {
              content: '';
              position: absolute;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background: #409eff;
              top: -2px;
            }

            &::before { left: 0; }
            &::after { right: 0; }
          }

          .stop-info {
            position: absolute;
            top: 8px;
            left: 50%;
            transform: translateX(-50%);
            color: #f56c6c;
            font-size: 12px;
          }
        }
      }
    }

    .cabin-list {
      padding-top: 16px;

      .cabin-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0;
        border-bottom: 1px dashed #ebeef5;

        &:last-child {
          border-bottom: none;
        }

        .cabin-info {
          .cabin-name {
            font-weight: bold;
            margin-right: 8px;
          }

          .class-code {
            color: #666;
            margin-right: 8px;
          }

          .seat-info {
            color: #409eff;
            font-size: 14px;
          }
        }

        .fare-info {
          text-align: right;

          .price {
            .amount {
              font-size: 20px;
              font-weight: bold;
              color: #f56c6c;
            }

            .discount {
              margin-left: 8px;
              color: #67c23a;
            }
          }

          .tax-info {
            color: #999;
            font-size: 12px;
            margin: 4px 0;
          }

          .baggage {
            color: #666;
            font-size: 12px;
          }
        }

        .rule-info {
          margin: 0 16px;
          
          .el-button {
            color: #409EFF;
            padding: 0;
            
            &:hover {
              color: #66b1ff;
            }
          }
        }
      }
    }
  }
}

.rule-details {
  .change-conditions {
    margin-bottom: 20px;
    padding-bottom: 16px;
    border-bottom: 1px solid #EBEEF5;

    h4 {
      margin-bottom: 8px;
      color: #303133;
    }

    p {
      color: #606266;
      font-size: 14px;
      line-height: 1.5;
    }
  }

  .rule-section {
    margin-bottom: 20px;
    
    .rule-item {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 12px 0;
      border-bottom: 1px dashed #EBEEF5;
      
      .time-info, .fee-info {
        .time-condition, .fee {
          font-weight: 500;
          margin-bottom: 4px;
        }
        
        .unit, .percent {
          font-size: 12px;
          color: #909399;
        }
      }
      
      .fee-info {
        text-align: right;
        
        .fee {
          color: #F56C6C;
        }
      }
    }
  }

  .baggage-info,
  .rule-remark {
    padding-top: 16px;
    border-top: 1px solid #EBEEF5;
    margin-top: 16px;
  }
}

.no-rules {
  text-align: center;
  padding: 20px;
  color: #909399;
}
</style> 