export const citiesData = [
  {
    label: '热门',
    options: [
      { value: 'PEK', label: '北京', pinyin: 'beijing', code: 'PEK' },
      { value: 'SHA', label: '上海', pinyin: 'shanghai', code: 'SHA' },
      { value: 'CAN', label: '广州', pinyin: 'guangzhou', code: 'CAN' },
      { value: 'SZX', label: '深圳', pinyin: 'shenzhen', code: 'SZX' },
      { value: 'CTU', label: '成都', pinyin: 'chengdu', code: 'CTU' },
      { value: 'HGH', label: '杭州', pinyin: 'hangzhou', code: 'HGH' },
      { value: 'WUH', label: '武汉', pinyin: 'wuhan', code: 'WUH' },
      { value: 'SIA', label: '西安', pinyin: 'xian', code: 'SIA' },
      { value: 'CKG', label: '重庆', pinyin: 'chongqing', code: 'CKG' },
      { value: 'KMG', label: '昆明', pinyin: 'kunming', code: 'KMG' }
    ]
  },
  {
    label: '国际/地区热门',
    options: [
      { value: 'HKG', label: '香港', pinyin: 'xianggang', code: 'HKG' },
      { value: 'TPE', label: '台北', pinyin: 'taibei', code: 'TPE' },
      { value: 'MFM', label: '澳门', pinyin: 'aomen', code: 'MFM' },
      { value: 'NRT', label: '东京', pinyin: 'dongjing', code: 'NRT' },
      { value: 'ICN', label: '首尔', pinyin: 'shouer', code: 'ICN' },
      { value: 'BKK', label: '曼谷', pinyin: 'mangu', code: 'BKK' },
      { value: 'SIN', label: '新加坡', pinyin: 'xinjiapo', code: 'SIN' },
      { value: 'KUL', label: '吉隆坡', pinyin: 'jilongpo', code: 'KUL' },
      { value: 'LON', label: '伦敦', pinyin: 'lundun', code: 'LON' },
      { value: 'PAR', label: '巴黎', pinyin: 'bali', code: 'PAR' },
      { value: 'NYC', label: '纽约', pinyin: 'niuyue', code: 'NYC' },
      { value: 'LAX', label: '洛杉矶', pinyin: 'luosanji', code: 'LAX' },
      { value: 'SYD', label: '悉尼', pinyin: 'xini', code: 'SYD' },
      { value: 'MEL', label: '墨尔本', pinyin: 'moerben', code: 'MEL' }
    ]
  }
];

export const cityMap = new Map();

citiesData.forEach(group => {
  group.options.forEach(city => {
    cityMap.set(city.value, {
      label: city.label,
      pinyin: city.pinyin,
      code: city.code
    });
  });
});

export const getCityByCode = (code) => {
  return cityMap.get(code);
};

export const searchCities = (keyword) => {
  if (!keyword) return [];
  
  keyword = keyword.toLowerCase();
  const results = [];
  
  citiesData.forEach(group => {
    group.options.forEach(city => {
      if (city.label.includes(keyword) || 
          city.pinyin.includes(keyword) || 
          city.code.toLowerCase().includes(keyword)) {
        results.push(city);
      }
    });
  });
  
  return results;
};

export const getAllCities = () => {
  const allCities = [];
  citiesData.forEach(group => {
    allCities.push(...group.options);
  });
  return allCities;
};

export const cities = [
  {
    code: 'SZX',
    name: '深圳',
    airportName: '宝安国际机场',
    pinyin: 'shenzhen',
    type: 'domestic'
  },
  {
    code: 'SHA',
    name: '上海',
    airportName: '虹桥国际机场',
    pinyin: 'shanghai',
    type: 'domestic'
  },
  // ... 其他城市保持不变
] 