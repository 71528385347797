<template>
  <div class="city-input-wrapper">
    <div class="input-trigger" @click.stop="handleClick">
      <el-input
        v-model="displayValue"
        :disabled="disabled"
        placeholder="请输入城市名称/拼音/三字码"
        @input="handleSearch"
        clearable
        :loading="loading"
      >
        <template slot="suffix">
          <i class="el-icon-loading" v-if="loading"></i>
          <i class="el-icon-arrow-down" v-else-if="!searchKeyword"></i>
          <i class="el-icon-close" v-else @click.stop="clearSearch"></i>
        </template>
      </el-input>
    </div>
    
    <template v-if="showSelector">
      <div class="city-selector-overlay" @click.stop="closeSelector"></div>
      
      <div class="city-selector" @click.stop>
        <!-- 搜索结果 -->
        <template v-if="searchKeyword">
          <div v-if="airports.length" class="search-results">
            <div 
              v-for="city in airports"
              :key="city.value"
              class="city-item"
              @click="selectCity(city)"
            >
              <div class="main-info">
                <span class="city-name">{{ city.cityName }}</span>
                <span class="airport-name">{{ city.airport }}</span>
              </div>
              <div class="sub-info">
                <span class="region" v-if="city.region">{{ city.region }}</span>
                <span class="country" v-if="city.countryName">{{ city.countryName }}</span>
                <span class="code">{{ city.value }}</span>
              </div>
            </div>
          </div>
          <div v-else class="no-result">未找到匹配的城市或机场</div>
        </template>

        <!-- 城市列表 -->
        <template v-else>
          <div v-for="group in citiesData" :key="group.label" class="city-group">
            <div class="section-title">{{ group.label }}</div>
            <div class="cities-grid">
              <div 
                v-for="city in group.options"
                :key="city.value"
                class="city-item"
                @click="selectCity({
                  cityName: city.label,
                  value: city.value,
                  cityCode: city.code,
                  airport: '',
                  region: '',
                  countryName: '中国'
                })"
              >
                {{ city.label }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { httpPost } from '@/api/http'
import { citiesData } from '@/constants/cities'

export default {
  name: 'CitySelector',
  props: {
    initialValue: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'international'
    }
  },
  data() {
    return {
      citiesData,  // 引入的城市数据
      searchKeyword: '',  // 搜索关键词
      displayValue: '',   // 显示值
      selectedCity: '',   // 选中的城市
      showSelector: false, // 是否显示选择器
      airports: [],       // 机场列表
      loading: false,     // 加载状态
      groupedAirports: {} // 分组后的机场数据
    }
  },
  
  watch: {
    // 监听初始值变化
    initialValue: {
      immediate: true,
      handler(val) {
        if (val) {
          this.displayValue = val
        }
      }
    }
  },
  
  computed: {
    filteredCities() {
      if (this.searchKeyword) {
        return this.airports
      } else {
        return this.groupedAirports
      }
    },
    // 获取所有字母分组
    alphabetGroups() {
      return Object.keys(this.groupedAirports).sort()
    }
  },
  methods: {
    handleClick(e) {
      if (this.disabled) return
      e.stopPropagation()
      this.showSelector = !this.showSelector
      
      if (this.showSelector) {
        // 计算下拉框位置
        this.$nextTick(() => {
          const inputEl = e.target.closest('.city-input-wrapper')
          const rect = inputEl.getBoundingClientRect()
          const selectorEl = this.$el.querySelector('.city-selector')
          
          // 设置下拉框位置
          selectorEl.style.setProperty('--dropdown-top', `${rect.bottom + 5}px`)
          selectorEl.style.setProperty('--dropdown-left', `${rect.left}px`)
        })
      }
    },
    
    toggleSelector() {
      if (!this.disabled) {
        this.showSelector = !this.showSelector
        console.log("toggleSelector 被调用，状态:", this.showSelector)
      }
    },
    
    closeSelector() {
      this.showSelector = false;
    },
    
    handleGlobalClick(e) {
      if (this.showSelector && !this.$el.contains(e.target)) {
        this.closeSelector();
      }
    },
    
    // 搜索机场
    async searchAirports(keyword) {
      try {
        this.loading = true
        const res = await httpPost('/airTicket/getAirPort', { 
          search: keyword,
          country_code: this.type === 'inChina' ? "CN" : ""
        })
        
        if (res.code === 1) {
          const formattedAirports = res.data.map(airport => {
            let firstLetter = 'A'
            if (airport.city_name_en) {
              firstLetter = airport.city_name_en.charAt(0).toUpperCase()
            } else if (airport.city_name) {
              firstLetter = airport.city_name.charAt(0)
            }

            return {
              label: `${airport.city_name || ''} - ${airport.airport_name || ''}`,
              value: airport.airport_code_3 || '',
              pinyin: (airport.city_name_en || '').toLowerCase(),
              airport: airport.airport_name || '',
              cityName: airport.city_name || '',
              cityCode: airport.airport_code_3 || '',
              countryName: airport.country_name || '',
              region: airport.region || airport.country_name || '',
              firstLetter: firstLetter
            }
          })

          // 搜索模式下直接显示结果
          this.airports = formattedAirports
        } else {
          throw new Error(res.message || '获取机场数据失败')
        }
      } catch (error) {
        console.error('搜索机场失败:', error)
        this.$message.error(error.message || '搜索失败，请重试')
      } finally {
        this.loading = false
      }
    },

    // 按字母分组机场数据
    groupAirports(airports) {
      this.groupedAirports = airports.reduce((groups, airport) => {
        const letter = airport.firstLetter
        if (!groups[letter]) {
          groups[letter] = []
        }
        groups[letter].push(airport)
        return groups
      }, {})
    },

    // 处理输入变化
    handleSearch(value) {
      this.searchKeyword = value
      this.displayValue = value
      
      if (this.searchKeyword) {
        // 有搜索关键词时才发起接口搜索
        this.searchAirports(this.searchKeyword)
      } else {
        // 无搜索关键词显示本地数据
        this.showLocalCities()
      }
      
      this.showSelector = true
    },

    // 选择城市
    selectCity(city) {
      this.selectedCity = city.label
      this.showSelector = false
      this.$emit('city-selected', {
        label: city.cityName,
        value: city.cityCode,
        airport: city.airport,
        airportCode: city.value,
        countryName: city.countryName,
        region: city.region
      })
    },

    // 清空搜索
    clearSearch() {
      this.searchKeyword = ''
      this.airports = []
    },

    // 新增显示本地城市数据的方法
    showLocalCities() {
      this.airports = []
      this.groupedAirports = {}
      // 不需要额外处理，直接使用 citiesData
    }
  },
  created() {
    // 初始化时获取所有机场数据
    this.searchAirports('')
  },
  mounted() {
    document.addEventListener('click', this.handleGlobalClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleGlobalClick);
  }
};
</script>

<style lang="scss" scoped>
.city-input-wrapper {
  position: relative;
  width: 100%;

  &.is-disabled {
    cursor: not-allowed;
    
    .input-trigger {
      pointer-events: none;
    }
  }
}

.input-trigger {
  cursor: pointer;
  width: 100%;

  :deep(.el-input) {
    .el-input__suffix {
      right: 5px;
      height: 100%;
      display: flex;
      align-items: center;
      
      .el-input__suffix-inner {
        height: 100%;
        display: flex;
        align-items: center;
      }
      
      .el-icon-loading,
      .el-icon-arrow-down,
      .el-icon-close {
        height: 16px;
        line-height: 16px;
        font-size: 14px;
        color: #909399;
      }
    }
  }
}

.city-selector-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: 9998;
}

.city-selector {
  position: fixed;
  z-index: 9999;
  background: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  max-height: 480px;
  overflow-y: auto;
  width: 480px;
  top: var(--dropdown-top);
  left: var(--dropdown-left);
  
  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #E4E7ED;
    border-radius: 3px;
  }
  
  &::-webkit-scrollbar-track {
    background: #F5F7FA;
  }
}

.city-group {
  & + .city-group {
    margin-top: 20px;
  }
  
  .section-title {
    font-size: 14px;
    color: #909399;
    margin-bottom: 12px;
    padding-left: 8px;
    font-weight: 500;
  }
  
  .cities-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 12px;
    
    .city-item {
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: #f5f7fa;
      border-radius: 4px;
      cursor: pointer;
      font-size: 14px;
      color: #606266;
      transition: all 0.3s;
      
      &:hover {
        background: #e6f7ff;
        color: #409EFF;
      }
    }
  }
}

.search-results {
  .city-item {
    padding: 12px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
    
    &:hover {
      background: #f5f7fa;
    }
    
    .main-info {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      
      .city-name {
        font-size: 14px;
        color: #303133;
        font-weight: 500;
        margin-right: 8px;
      }
      
      .airport-name {
        font-size: 13px;
        color: #606266;
      }
    }
    
    .sub-info {
      font-size: 12px;
      color: #909399;
      
      .region {
        margin-right: 8px;
        &:after {
          content: '·';
          margin-left: 8px;
        }
      }
      
      .country {
        margin-right: 8px;
        &:after {
          content: '·';
          margin-left: 8px;
        }
      }
      
      .code {
        color: #c0c4cc;
      }
    }
  }
}

.no-result {
  padding: 24px;
  text-align: center;
  color: #909399;
  font-size: 14px;
}

</style> 