import { httpPost } from './http'

// 登录
export function login(data) {
  return httpPost('/auth/pass', data)
}

// 注册
export function register(data) {
  return httpPost('/auth/register', data)
}

// 发送验证码 - 添加 type 参数
export function sendVerifyCode(data) {
  return httpPost('/auth/send', data)
}

// 验证码登录
export function codeLogin(data) {
  return httpPost('/auth/in', data)
}
  
// 重置密码
export function resetPassword(data) {
  return httpPost('/auth/forget', data)
} 