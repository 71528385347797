<template>
  <div class="home">
    <div class="banner-section">
      <div class="container banner-container">
        <SideNav />
        <div class="main-content">
          <FlightSearchSection mode="home" />
        </div>
      </div>
    </div>

    <div class="quick-services">
      <div class="container">
        <el-row :gutter="20">
          <el-col :span="6" v-for="service in quickServices" :key="service.id">
            <div class="service-item">
              <i :class="service.icon"></i>
              <div class="service-info">
                <h3>{{ service.title }}</h3>
                <p>{{ service.desc }}</p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    

  </div>
</template>

<script>
import FlightSearchSection from '@/components/FlightSearchSection.vue'
import SideNav from '@/components/SideNav.vue'

export default {
  name: 'HomePage',
  components: {
    FlightSearchSection,
    SideNav
  },
  data() {
    return {
      quickServices: [
        { id: 1, title: '订单保障', desc: '保证服务品质', icon: 'el-icon-shield-check' },
        { id: 2, title: '消费者保障', desc: '保障消费者权益', icon: 'el-icon-umbrella' },
        { id: 3, title: '7×24小时服务', desc: '专业客服团队', icon: 'el-icon-service' },
        { id: 4, title: '旅行保险', desc: '保障出行安全', icon: 'el-icon-first-aid-kit' }
      ],
      hotDestinations: [
        { id: 1, name: '三亚', desc: '阳光海滩', image: 'https://via.placeholder.com/200x150?text=Sanya' },
        { id: 2, name: '丽江', desc: '古城文化', image: 'https://via.placeholder.com/200x150?text=Lijiang' },
      ],
      specialFlights: [
        {
          from: '上海',
          to: '北京',
          departDate: '11-30',
          returnDate: '12-01',
          price: 520,
          discount: '4.5'
        },
      ],
      vacationPicks: [
        {
          id: 1,
          title: '三亚5日4晚自由行',
          description: '含机票+酒店 海景房',
          price: 2999,
          sales: 1234,
          tag: '热卖',
          image: 'https://via.placeholder.com/300x200?text=Vacation1'
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-section {
  background: linear-gradient(180deg, #2681ff 0%, #f5f5f5 100%);
  min-height: 80px;
  padding-top: 45px;
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 280px;
    opacity: 0.1;
  }
}

.banner-container {
  position: relative;
  z-index: 1;
  padding: 20px 0;
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 80px;
}

.side-nav {
  position: absolute;
  left: 0;
  bottom: 20px;
  background: #4095ffd1;
  border-radius: 8px;
  width: 75px;
  z-index: 1;
}

.main-content {
  flex: 1;
  padding-top: 20px;
  justify-content: center;
  padding-left: 70px;
  height: auto;
}

.quick-services {
  background: #fff;
  padding: 40px 0;
  
  .service-item {
    display: flex;
    align-items: center;
    gap: 15px;
    
    i {
      font-size: 40px;
      color: #2681ff;
    }
    
    .service-info {
      h3 {
        font-size: 18px;
        margin-bottom: 5px;
      }
      
      p {
        color: #666;
        font-size: 14px;
      }
    }
  }
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  
  h2 {
    font-size: 24px;
    color: #333;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px 20px 20px;
}

.hot-destinations {
  padding: 40px 0;
  background: #fff;
  
  .dest-card {
    text-align: center;
    
    img {
      width: 100%;
      border-radius: 8px;
      margin-bottom: 10px;
    }
    
    h3 {
      font-size: 16px;
      margin-bottom: 5px;
    }
    
    p {
      color: #666;
      font-size: 14px;
    }
  }
}

.special-flights {
  padding: 40px 0;
  background: #f5f5f5;
  
  .tabs {
    span {
      padding: 5px 15px;
      cursor: pointer;
      
      &.active {
        color: #2681ff;
        border-bottom: 2px solid #2681ff;
      }
    }
  }
}

.vacation-picks {
  padding: 40px 0;
  background: #fff;
  
  .vacation-card {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    
    .image-wrapper {
      position: relative;
      
      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
      
      .tag {
        position: absolute;
        top: 10px;
        left: 10px;
        background: #ff6666;
        color: #fff;
        padding: 2px 8px;
        border-radius: 4px;
      }
    }
    
    .content {
      padding: 15px;
      
      h3 {
        font-size: 18px;
        margin-bottom: 10px;
      }
      
      .desc {
        color: #666;
        margin-bottom: 10px;
      }
      
      .price-info {
        display: flex;
        align-items: center;
        gap: 5px;
        
        .price {
          color: #ff6666;
          font-size: 20px;
          font-weight: bold;
        }
        
        .unit {
          color: #ff6666;
        }
        
        .sales {
          color: #999;
          font-size: 12px;
          margin-left: auto;
        }
      }
    }
  }
}
</style> 