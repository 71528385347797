import request from '@/utils/request'

export function httpPost(path, data, config = {}) {
  return request({
    url: `/api${path}`,
    method: 'post',
    data,
    ...config,
    baseURL: process.env.NODE_ENV === 'production' 
      ? window.location.origin  // 生产环境使用当前域名
      : 'http://1.117.74.184:18081'  // 开发环境使用固定地址
  })
}

export function httpGet(path, params) {
  return request({
    url: `/api${path}`,
    method: 'get',
    params,
    baseURL: process.env.NODE_ENV === 'production' 
      ? window.location.origin  // 生产环境使用当前域名
      : 'http://1.117.74.184:18081'  // 开发环境使用固定地址
  })
}
