<template>
  <div class="side-nav">
    <ul class="nav-list">
      <li class="nav-item">
        <div class="nav-link">
          <i class="el-icon-position"></i>
          机票
        </div>
        <div class="sub-nav">
          <div class="sub-nav-inner">
            <div class="sub-nav-section">
              <h4>机票预订</h4>
              <div class="sub-nav-links">
                <a href="#">国内机票</a>
                <a href="#">国际/港澳台</a>
                <a href="#">特价机票</a>
                <a href="#">航班动态</a>
                <a href="#">航空公司</a>
                <a href="#">中转联程</a>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="nav-item">
        <div class="nav-link">
          <i class="el-icon-truck"></i>
          火车票
        </div>
        <div class="sub-nav">
          <div class="sub-nav-inner">
            <div class="sub-nav-section">
              <h4>火车票预订</h4>
              <div class="sub-nav-links">
                <a href="#">国内火车票</a>
                <a href="#">高铁票</a>
                <a href="#">动车票</a>
                <a href="#">特价票</a>
                <a href="#">时刻表</a>
                <a href="#">中转查询</a>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SideNav'
}
</script>

<style lang="scss" scoped>
.side-nav {
  position: absolute;
  left: 0;
  top: 0;
  width: 80px;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100;
}

.nav-list {
  list-style: none;
  margin: 0;
  padding: 10px 0;
}

.nav-item {
  position: relative;
  height: 56px;
  
  &:hover {
    background: #fff;
    
    .nav-link {
      color: #2681ff;
    }
    
    .sub-nav {
      display: block;
    }
  }
}

.nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  
  i {
    font-size: 24px;
    margin-bottom: 4px;
  }
}

.sub-nav {
  display: none;
  position: absolute;
  left: 80px;
  top: 0;
  min-width: 200px;
  background: #fff;
  box-shadow: 2px 0 8px rgba(0,0,0,0.1);
  border-radius: 0 4px 4px 0;
  padding: 15px 0;
}

.sub-nav-inner {
  padding: 0 20px;
}

.sub-nav-section {
  h4 {
    font-size: 14px;
    color: #333;
    margin-bottom: 10px;
    padding-bottom: 8px;
    border-bottom: 1px solid #eee;
  }
}

.sub-nav-links {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  
  a {
    color: #666;
    text-decoration: none;
    font-size: 14px;
    padding: 5px 0;
    
    &:hover {
      color: #2681ff;
    }
  }
}
</style> 