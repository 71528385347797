import axios from 'axios'
import { Message } from 'element-ui'

const service = axios.create({
  baseURL: 'https://trip.missany.com',
  timeout: 50000
})

// 请求拦截器
service.interceptors.request.use(
  config => {
    config.headers['Authorization'] = localStorage.getItem('token') 
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.code === 1) {
      return res
    } else if (res.info === 'Error: 返回航班数据为空') {
      return {
        code: 1,
        info: 'success',
        data: {
          flightDetailList: []
        }
      }
    } else {
      Message({
        message: res.info || '请求失败',
        type: 'error',
        duration: 3000
      })
      return Promise.reject(new Error(res.info || '请求失败'))
    }
  },
  error => {
    console.log('err' + error)
    Message({
      message: error.message,
      type: 'error',
      duration: 3000
    })
    return Promise.reject(error)
  }
)

export default service 